@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

body {
  font-size: 14px;
  color: #716e6e;
  background: url(https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1666160536/SYC-SYG-Logos-Images/purplesweeptakes-bg_zopwtp.jpg) no-repeat center center fixed;
  background-size: cover !important;
  font-weight: 300;
  line-height: 22px;
  padding-bottom: 100px;
  min-height: 100%;
  position: relative;
  margin: 0;
}

/* Dynamic Css Start  */
.white-box {
  border: 4px solid #43268f;
}

/* Dynamic Css End  */

html {
  height: 100%;
  box-sizing: border-box;
}

form#signin {
  margin-bottom: 0px;
}

.white-box.thank {
  text-align: center;
  font-family: Helvetica;
}

.white-box-n img {
  margin-bottom: 20px;
}

.white-box-n {
  text-align: center;
}

.white-box-n button:hover {
  background: #16419c;
  border: 1px solid #223d99;
}

.white-box-n p {
  color: red;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}

.white-box-n button {
  background: #00a8e2;
  border: 1px solid #00a8e2;
  outline: none;
  color: #fff;
  padding: 6px 40px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
}

.reward img {
  width: 100%;
}

i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}

.mt-2 {
  margin-top: 20px;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #000;
  text-decoration: underline;
}

ul.points li:first-child {
  border: none;
}

h1 {
  color: #000;
  font-family: "Raleway", sans-serif;
}

h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: "Raleway", sans-serif;
}
.glyphicon-search:before {
  content: "\e003";
  font-family: "Glyphicons Halflings";
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  padding: 0px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 0;
}

p {
  line-height: 20px;
  color: #000;
  font-weight: 400;
}

p:last-child {
  margin-bottom: 0px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

:before,
:after {
  font-family: Material-Design-Iconic-Font;
}

p.note {
  background: #cb2330;
  padding: 14px;
  color: #fff;
}

ul.info-data {
  padding: 0px;
  list-style: none;
  margin: 10px auto;
  display: inline-block;
}

ul.info-data li {
  border: 1px solid #ccc;
  color: #212121;
  margin-top: -1px;
  padding: 4px 52px;
}

.clear:after {
  content: "";
  width: 100%;
  clear: both;
  display: table;
}

.check-boexs {
  float: left;
  margin-bottom: 20px;
}

.white-box {
  background: rgba(255, 255, 255, 0.85);
  padding: 30px 30px;
  border-width: 4px 0px;
  /* border: 4px solid #cb2330; */
}

.white-box h1 {
  color: #43268f;
  font-size: 27px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}

a.link {
  color: #000;
  text-decoration: none;
}

a.link:hover {
  text-decoration: underline;
  color: #000;
}

form.form-outer label {
  width: 100%;
  /* text-transform: uppercase; */
  text-transform: inherit;
}

form.form-outer label span.carddetail {
  display: block;
}

.red_point {
  color: #f00;
}


form.form-outer input[type="text"],
form.form-outer input[type="email"],
form.form-outer input[type="tel"],
form.form-outer select {
  background: #ffffff00;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #3e3935;
}

.input-outer1 {
  position: relative;
  margin: 0px 0px;
}

.input-outer {
  position: relative;
  margin: 20px 0px;
}

p.logo-text {
  font-size: 45px;
  line-height: 1.4;
  font-weight: 700;
  color: #3e3934;

  margin-top: 13px;
}

p.error {
  color: #e00d0d;
  border-radius: 30px;
  padding: 0px 0px;
  display: inline-block;
}

button#pop {
  border-color: #fff0;
}

div#inlineContent img {
  width: 300px;
}

div#ob_content p {
  text-align: center;
}

.footer p {
  color: #fff;
  padding: 15px 0px;
}

.white-box.form h2 {
  border: 1px solid #ccc;
  margin-top: 25px;
  padding: 10px 0px;
  border-width: 1px 0px 1px 0px;
}

form.form-outer.register label {
  text-transform: initial;
  font-weight: 400;
  font-size: 15px;
}

form.form-outer.register label span {
  color: #f00;
  font-size: 23px;
}

.phone-outer input {
  width: 30%;
}

input#choicekw0 {
  width: 10%;
  display: block;
  margin: 0 auto;
}

input#choicekw1 {
  width: 10%;
  display: block;
  margin: 0 auto;
}


input#choicekw2 {
  width: 10%;
  display: block;
  margin: 0 auto;
}

form.form-outer.register select {
  background: #e8e8e8;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #dadada;
}

input#choicekw3 {
  width: 10%;
  display: block;
  margin: 0 auto;
}

.reward input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}

.reward1 img {
  width: 120px;
}

.reward1 input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}

.reward1 {
  max-height: 310px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  height: 200px;
}

.reward {
  /* max-height: 160px; */
  text-align: center;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  /* height: 295px; */
}

form.form-outer.register button {
  position: static;
  border-radius: 30px;
  height: 46px;
  width: 110px;
  background: #231f20;
  border: 1px solid #211d1e;
  color: #fff;
  margin-top: 31px;
  float: right;
}

form.form-outer.register button:hover {
  background: #cb2330;
  border: 1px solid #cb2330;
}

/*Responsive table*/
/*======
Media Queries
=======*/
/*========================*/
@media (min-width: 1200px) and (max-width: 1910px) {
  /* .container-fluid{width:60%;} */
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container-fluid {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  p.logo-text {
    float: right;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }

  ul.points li img {
    margin-bottom: 10px;
  }

  .container-fluid {
    width: 90%;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .white-box {
    text-align: center;
  }

  p.logo-text {
    text-align: center;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 700;
    color: #3e3934;
    margin-top: 13px;
    margin-bottom: 30px;
  }

  .phone-outer input {
    width: 28.6% !important;
  }

  ul.points li img {
    margin-bottom: 10px;
  }

  .container-fluid {
    width: 90%;
  }

  .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }

  .modal-content {
    width: 94% !important;
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }

  .phone-outer input {
    width: 28.6% !important;
  }

  .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }

  .phone-outer input {
    width: 28.6% !important;
  }

  .check-boexs {
    /* margin-right: 15px; */
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

h1 {
  text-align: center;
}

.phonenumber {
  font-size: 14px;
  font-family: Arial;
}

.recap {
  width: 304px;
  height: 78px;
}

.styling {
  width: 250px;
  height: 40px;
  border: 1px solid rgb(193, 193, 193);
  margin: 10px 25px;
  padding: 0px;
  resize: none;
  display: none;
}

i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}

.errorMsg1 {
  color: #e00d0d;
  border-radius: 30px;
  padding: 0px 0px;
  text-transform: none;
  font-weight: 400;
  /* margin-left: 60px;
  margin-bottom: 21px;
  margin-top: -20px; */
}

.errorMsg {
  color: #e00d0d;
  border-radius: 30px;
  padding: 0px 0px;
  text-transform: none;
  font-weight: 600;
}

input#myCheck {
  display: inline-block;
  width: auto;
}

svg {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}

ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .container-fluid {
    width: 100%;
  }
}

b,
strong {
  font-weight: 700;
}

li.removing {
  border-bottom: 1px solid #fffafa;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
}

@media (min-width: 320px) and (max-width: 767px) {
  img.card {
    margin: 0 auto !important;
  }

  .container-fluid {
    width: 90% !important;
  }

  .white-box {
    width: 100%;
    text-align: center;
  }

  .white-box h1 {
    text-align: center;
    font-size: 22px;
  }

  input#submit {
    margin-left: auto !important;
    margin-right: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    width: 90% !important;
  }

  .white-box {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-11 {
    flex: 0 0 auto;
    width: -webkit-fill-available;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .white-box {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-11 {
    width: 91.66666667% !important;
  }
}

@media (min-width: 1911px) and (max-width: 5000px) {
  .container-fluid {
    width: 900px !important;
  }

  .white-box {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  img.card {
    margin: 0 auto !important;
  }

  .container-fluid {
    width: 90% !important;
  }
}

label.formsub {
  max-width: 100%;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: unset;
}

.box {
  padding: 15px;
  margin: 15px;
  background-color: #00a8e2;
}

form.form-outer.register label {
  text-transform: initial;
  font-weight: 400;
  font-size: 15px;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 4px 6px 0;
  margin-top: 1px \9;
  line-height: normal;
}

.reward input[type="radio"] {
  margin: 0px;
}

.imgcolor {
  margin-top: 10px;
  /* box-shadow: 0 10px 28px rgb(0 0 0 / 0%), 0 10px 10px rgb(0 0 0 / 11%); */
  width: 100%;
  border-radius: 6px;
  margin-right: 8px;
}

@media (min-width: 320px) and (max-width: 575px) {
  .check-boexs {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .container-fluid {
    width: 60% !important;
  }
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  /* background-color: #5d5f94; */
  background-color: #3e3936;
  color: #fff;
}

:before,
:after {
  font-family: Material-Design-Iconic-Font;
}

ul.terms-list li:before {
  content: "\f2fb";
  position: absolute;
  left: 12px;
  /* color: #5d5f94; */
  color: #3e3936;
}

ul.terms-list {
  list-style: none;
  font-size: 15px;
}
label.agree.label div {
  display: inline;
}

.modal-footer {
  width: 571px;
  height: 67px;
}

li {
  line-height: 22px;
}


form.form-outer.register label {
  text-transform: initial;
  font-weight: 400;
  font-size: 15px;
}

label.heading1 {
  display: inline;
}

label.inline {
  display: inline;
  width: auto;
}

label.heading1.ml-5 {
  margin-left: 50px;
}

label.heading1.ml-3 {
  margin-left: 80px;
}

div#text {
  text-align: center;
}

.alert.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  margin-top: 25px;
  margin-bottom: 18px;
  font-weight: 400;
}

@media (min-width: 1911px) and (max-width: 10000px) {
  .container-fluid {
    width: 900px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
}
.over-hide {
  width: 100%;
  overflow: auto;
} */
.overflow-hider {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}