.logo {
    padding: 15px 0;
    background: #ffffffd9;
    margin: 40px auto;
  }
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .logo img {
    margin: 0px auto;
    text-align: center;
    width: 45%;
  }
  