input.sub-btn1 {
    background: #43268f;
    outline: none;
    color: #fff;
    padding: 11px 15px;
    border: 1px solid #43268f;
    border-radius: 50px;
    width: 100%;
  }
  input.sub-btn1:hover {
    background: #6C44C7;
    border: 1px solid #6C44C7;
  }

  a.btn.btn-md.btn-primary {
    background-color: #43268f;
    border-color: #43268f;
    color: #fff !important;
    text-decoration: none !important;
  }
  a.btn.btn-md.btn-primary:hover{
    color: #fff !important;
    background-color: #6C44C7;
    border-color: #6C44C7;
    text-decoration: none !important;
  }